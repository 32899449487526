import type { RDMDOpts } from '@readme/iso';

import React from 'react';

import useEnvInfo from '@core/hooks/useEnvInfo';

import RDMD, { TOC } from '@ui/RDMD';

import MigrationError from './MigrationError';

interface DocBodyProps {
  dehydrated?: {
    body: string;
    toc?: string;
    css?: string;
  };
  docBody: string;
  editUrl: string;
  footer: React.ReactNode;
  hasMdxError: boolean;
  hideTOC: boolean;
  isAdmin?: boolean;
  mdx: boolean;
  opts: RDMDOpts;
}

const DocBody = ({
  isAdmin,
  hideTOC,
  dehydrated,
  docBody,
  editUrl,
  mdx,
  opts = {},
  footer,
  hasMdxError = false,
}: DocBodyProps) => {
  const { isClient } = useEnvInfo();

  return (
    <div className="grid-container-fluid" id="content-container">
      <section className={`content-body grid-${hideTOC ? 100 : 75}`}>
        {!!hasMdxError && !!editUrl && !!isAdmin && <MigrationError editUrl={editUrl} />}
        <RDMD
          key={isClient ? window.location.pathname : null}
          body={docBody}
          className="rm-Markdown markdown-body ng-non-bindable"
          css={dehydrated?.css}
          dehydrated={dehydrated?.body}
          mdx={!hasMdxError && mdx}
          opts={opts}
          style={{ marginLeft: 31 }}
        />
        {!!footer && footer}
      </section>
      {!hideTOC && (
        <section className="content-toc grid-25">
          <TOC body={docBody} dehydrated={dehydrated?.toc} mdx={!hasMdxError && mdx} opts={opts} />
        </section>
      )}
    </div>
  );
};

export default DocBody;
